//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PrivateSeaDeploy as _PrivateSeaDeploy, PrivateSeaSetnum } from '@/api/updateUserInfo/notice';
import Head from '@/components/head/index';
export default {
  name: 'PrivateSeaDeploy',
  components: {
    Head: Head
  },
  data: function data() {
    return {
      title: '私海数据配置',
      tableData: [],
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false
      // 系统教程
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.PrivateSeaDeploy();
    } else {}
  },
  methods: {
    PrivateSeaDeploy: function PrivateSeaDeploy() {
      var _this = this;
      _PrivateSeaDeploy().then(function (respomse) {
        _this.tableData = respomse.data;
      });
    },
    editPrivateSea: function editPrivateSea(id, num) {
      var _this2 = this;
      //编辑
      var data = {
        uid: id,
        customer_num: num
      };
      PrivateSeaSetnum(data).then(function (res) {
        _this2.PrivateSeaDeploy();
        _this2.$notify({
          title: '成功',
          message: '编辑成功',
          type: 'success',
          duration: 2000
        });
      });
    }
  }
};