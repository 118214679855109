var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container framework" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "88px" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                ref: "tb",
                staticStyle: { width: "60%", "margin-bottom": "20px" },
                attrs: {
                  data: _vm.tableData,
                  "row-key": "flag",
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "部门名称",
                    "min-width": "160",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "", width: "260", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.customer_num ||
                            scope.row.customer_num == 0
                              ? _c("el-input", {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["编辑"],
                                      expression: "['编辑']",
                                    },
                                  ],
                                  attrs: { size: "small", maxlength: "4" },
                                  on: {
                                    input: function ($event) {
                                      scope.row.customer_num =
                                        scope.row.customer_num.replace(
                                          /[^0-9]/g,
                                          ""
                                        )
                                    },
                                    change: function ($event) {
                                      return _vm.editPrivateSea(
                                        scope.row.id,
                                        scope.row.customer_num
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.customer_num,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "customer_num", $$v)
                                    },
                                    expression: "scope.row.customer_num",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3056619446
                  ),
                }),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }